@import '@styles/variables'; 

.ErrorBoundary {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  min-height: 100vh;
  background-image: url("../../../static/images/personal-account/background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__content {
    padding: 40px 80px;
    background-color: #fafafa;
    opacity: 0.95;
  }

  &__title {
    color: $color-accent-title;
  }
}
