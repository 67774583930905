
// positioning
.d-flex_row_sb_fs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.d-flex_col_sb_fs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.d-flex_col_fs_fs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.d-flex_row_fs_cen {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.d-flex_row_fs_cen {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.d-flex_col_fs_cen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

// margins
.m_mt-4 {
  margin-top: 4px;
}

.m_mt-8 {
  margin-top: 8px;
}

.m_mt-16 {
  margin-top: 16px;
}

.m_mt-32 {
  margin-top: 32px;
}

.m_mt-64 {
  margin-top: 64px;
}
