@import '@styles/variables';

.#{$rt-namespace}__close-button {
  color: $color-white;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  
  &--default {
    color: $color-black;
    opacity: 0.3;
  }

  & > svg {
    fill: currentColor;
    height: 16px;
    width: 14px;
  }

  &:hover, &:focus {
    opacity: 1;
  }
}
