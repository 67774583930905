@import "@styles/variables";
@import "@styles/mixins";

.RadioCustom {
  display: flex;
  align-items: center;
  
  input {
    display: none;

    // disabled
    &:disabled {
      & + label {
        pointer-events: none;
        span {
          color: rgba($color-black, 0.5);
        }
        &::before, &::after {
          cursor: default;
        }

        &::before {
          border: 1px solid rgba($color-black, 0.1);
          background-color: rgba($color-black, 0.05);
        }
      }
    }

    // checked={true}
    &:checked {
      & + label {
        &::before { border: 1px solid $color-accent; }
        &::after { display: block; }
      }
    }
    
    & + label {
      position: relative;
      display: inline-flex;
      padding-left: 30px;
      margin-bottom: 0;

      span::selection {
        background-color: transparent;
        color: inherit;
      }

      &:hover {
        &::before {
          border: 1px solid $color-accent;
        }
      }

      &::before, &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        cursor: pointer;
        transition: all $default-duration;
      }

      &::before {
        left: 0;
        top: 50%;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        border: 1px solid rgba($color-black, 0.3);
      }

      &::after {
        display: none;
        left: 3px;
        top: 50%;
        margin-top: -4px;
        width: 8px;
        height: 8px;
        background-color: $color-accent;
      }

      // labelText
      span {
        color: $color-black;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}