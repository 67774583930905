@import "@styles/variables";

.Captcha {
  text-align: left;

  &__label {
    display: block;
    margin-bottom: 3px;
    color: #00000080;
    font-size: 16px;
  }

  &__update {
    margin-bottom: 8px;

    &-img {
      margin-right: 10px;
    }

    &-link {
      display: inline-flex;
      align-items: center;
      color: $color-accent;
      font-size: 16px;
      line-height: 16px;

      &:focus {
        box-shadow: 0 0 1px 1px $color-accent;
      }

      &:hover {
        color: $color-accent;
      }
    }
  }
}
