// Configuration
@import 'static/styles/variables';
@import 'static/styles/mixins';
@import 'static/styles/userNotification/main';
@import 'static/styles/form';
@import 'static/styles/helpers';
@import 'static/styles/buttons';

// Libs
@import '@cuba-platform/react/dist/index.min.css';
@import '~antd/dist/antd.min.css';
@import '~bootstrap';
@import '~react-datepicker';

// TODO - need refactoring this styles
html,
body,
#root {
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  width: 100%;
  color: $color-black;
  font-size: 20px;
  font-family: $system-fonts;
  text-align: center;
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
  height: 100%;

  @include mobile {
    height: auto;
  }
}

// стили которые были в App.scss
.animation {
  display: block;
  background-color: $color-white;
  opacity: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
  position: fixed;

  &-in { animation: fade-in ease 1.6s; }

  &-out { animation: fade-out ease 1s; }
}

.submitButton {
  margin: 28px auto;
  width: 100%;
  padding: 8px 30px;
}

.main-layout { height: auto; }

.narrow-layout {
  max-width: 1024px;
  margin: auto;
}

.narrow-layout.ant-card {
  max-width: 1024px;
  margin: auto;
}

.note {
  color: #535353;
  font-size: 13px;
  width: 100%;

  &-2 {
    line-height: 1.3;
    margin: 0;
  }

  & + .form-group { margin-top: 6px; }
}

.ant-button { height: 44px; }

.ant-layout-footer-transparent {
  background: none;
}

// rewrite default input[type="checkbox"]:checked
input[type="checkbox"] {
  &:checked {
    &::before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background-color: $color-accent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 9px auto;
      background-image: url(./static/icons/check.svg);
    }
  }
}

// mobile specific
.isMobile {
  input[type="checkbox"] {
    &:checked {
      &::before {
        width: 16px;
        height: 16px;
      }
    }
  }
}

// rewrite .ant-popover-buttons
.ant-popover-buttons {
  button {
    &.ant-btn {
      &:hover {
        color: $color-accent;
        border-color: $color-accent;
      }
    }

    &.ant-btn-primary {
      background-color: $color-accent;
      border-color: $color-accent;
      color: $color-white;
      &:hover { color: $color-white; }
    }
  }
}

// rewrite .ant-modal-confirm-btns
.ant-modal-confirm-btns {
  .ant-btn {
    &:hover {
      color: $color-accent;
      border-color: $color-accent;
    }
  }

  .ant-btn-primary {
    background-color: $color-accent;
    border-color: $color-accent;
    color: $color-white;
    &:hover { color: $color-white; }
  }
}

// react-datepicker rewrite to accent-color
.react-datepicker {
  &__day {
    &--selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--keyboard-selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-selecting-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
  }

  &__month-text {
    &--selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--keyboard-selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-selecting-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
  }

  &__quarter-text {
    &--selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--keyboard-selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-selecting-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
  }
  &__year-text {
    &--selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--keyboard-selected {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-selecting-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    }
    &--in-range {
      background-color: $color-accent;
      &:hover { background-color: $color-accent-hover; }
    } 
  }
}
