@import "@styles/variables";
@import "@styles/mixins";

.CompanyAccount {
  height: 100%;

  &__menu {
    padding: 32px 32px 0;
    background-color: $color-white !important;

    &-close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
      background-image: url("../../../static/icons/close.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 15px auto;
      cursor: pointer;
    }

    &--desktop {
      @include mobile {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include mobile {
        position: fixed !important;
        top: 50px;
        bottom: 0;
        left: 0;
        z-index: 5;
        display: block;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
      }
    }

    :global {
      .ant-menu-vertical {
        border: unset;

        & > .ant-menu-item {
          height: auto;
          line-height: 1;
        }

        .ant-menu-item {
          margin: 0;
          padding: 0;
          color: $color-gray;
          text-align: left;

          &.ant-menu-item-active,
          &.ant-menu-item-selected {
            background-color: unset;
          }

          & > a {
            display: inline;
            font-weight: bold;
            font-weight: 400;
            text-decoration: none;

            &.active {
              font-weight: 900;
            }
          }

          &-selected > a,
          &-selected > a:hover,
          & > a:hover {
            color: $color-gray;
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  &__content[class] {
    :global {
      .ant-typography,
      .ant-typography h2,
      h2.ant-typography {
        color: #3c3c3c !important;
      }

      // TODO - doesn't work 
      .ant-modal-content { border-radius: 15px; }
    }

    padding: 32px 64px;
    text-align: left;
    background-color: $color-gray-lite;

    @include mobile {
      padding-top: 75px;
      padding-right: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      overflow: hidden;
      min-height: 100vh;
    }
  }

  &__spin-container {
    :global {
      .ant-spin {
        margin: auto;
      }
    }

    display: flex;
    align-content: center;
    width: 100vw;
    height: 100vh;
  }
}
