@import "@styles/variables";
@import "@styles/mixins";
@import '~bootstrap/scss/_forms';
// @import '~bootstrap';

.form {
  &-container {
    width: 910px;
    margin: 0;
    padding: 30px 50px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.9);

    @include laptop {
      width: 100%;
      padding: 30px 108px;
      margin: auto 0;
      background-color: rgba(250, 250, 250, 0.9);
    }

    @include mobile {
      padding: 24px 24px;
    }

    &-map {
      width: 1024px;
      height: calc(100vh - 100px);
      padding: 30px 40px;
      overflow-y: auto;

      @include laptop {
        width: 100%;
        height: auto;
        padding: 30px 30px;
      }

      @include mobile {
        padding: 30px 20px;
      }
    }
  }

  &-title {
    margin: auto;
    font-size: $font-size-m;
    font-weight: bold;
    text-align: left;

    @include mobile {
      font-size: $font-size-xs;
    }
  }

  &-options {
    font-size: $font-size-m;

    @include mobile {
      font-size: $font-size-xs;
    }

    $ratio: 0.5;
    $ratio-m: 0.4;

    input[type='radio'] {
      margin-top: $font-size-m * $ratio;

      @include mobile {
        margin-top: $font-size-xxs * $ratio-m;
      }
    }
  }

  &-long {
    margin-top: 20px;
    height: auto;
  }

  &-checkbox-description {
    margin-top: 4px;
    font-size: 14px;
  }
}

.date-picker {
  border: none;
  &-x2 {
    max-width: 300px;
  }
}

.error-border,
.error-border-datepicker {
  border: 1px solid red !important;
  border-radius: 4px;
}

.dt-width {
  width: 200px;
}

.del-btn-content {
  width: 16px;
  height: 16px;
  padding: 0;
  box-sizing: border-box;
  margin: 6px auto;
  border-radius: 50%;
  line-height: 1;
  font-size: 10px;
  border: 1px solid gray;
  background: none;
  outline: none;
  transition: 0.2s;
  &:disabled {
    opacity: 0.5;
  }
}

.submit-button {
  margin: 28px auto;
  width: 100%;
  padding: 8px 30px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fafafa;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  // inputs focus
  &:focus[class] {
    border: 1px solid rgba($color-accent, 0.75);
    box-shadow: 0 0 5px 0 rgba($color-accent, 0.1);
  }

  &:disabled,
  &[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
}

.form-text {
  @extend .form-text !optional;
}