@import "@styles/variables";
@import "@styles/mixins";

// button
.ButtonCustom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  min-width: 145px;
  min-height: 36px;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: all $default-duration;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover { text-decoration: none; }

  // primary
  &__primary {
    background-color: $color-accent;
    &:hover { background-color: $color-accent-hover; }
    span { color: $color-white; }
  }

  &__primary-big {
    min-width: 220px;
    min-height: 40px;
    background-color: $color-accent;
    &:hover { background-color: $color-accent-hover; }
    span { color: $color-white; }
    @include mobile {
      min-width: 200px;
      min-height: 36px;
    }
  }

  &__primary-outlined {
    background-color: transparent;
    border: 1px solid $color-accent;
    span { color: $color-accent; }
    &:hover {
      background-color: $color-accent;
      span { color: $color-white; }
    }
  }

  // secondary
  &__secondary {
    background-color: transparent;
    border: 1px solid darken($color-secondary, 20%);
    &:hover { background-color: rgba($color-secondary-hover, 0.3); }
    span { color: $color-black; }
  }

  &__secondary-big {
    min-width: 220px;
    min-height: 40px;
    background-color: transparent;
    border: 1px solid darken($color-secondary, 20%);
    &:hover { background-color: rgba($color-secondary-hover, 0.3);  }
    span { color: $color-black; }
    @include mobile {
      min-width: 200px;
      min-height: 36px;
    }
  }
  
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: 0 0 1px 1px $color-accent-hover;
  }

  span {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.15px;
    @include tablet {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
