@import "@styles/variables";
@import "@styles/mixins";

.Header {
  width: 100%;

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 35px;
    padding: 5px 30px;
    color: $color-white;
    font-size: 16px;
    background: #8e8e8e;

    &-span {
      margin-right: 10px;
    }

    @include mobile {
      padding: 5px 15px;
    }

    &-link[class] {
      color: $color-white;

      @include hover {
        color: $color-white;
      }
    }
  }

  &__contacts {
    @include mobile {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }

    &-span {
      color: #eee;

      @include tablet {
        display: none;
      }
    }

    &-link {
      margin-right: 15px;
      color: #eee;

      @include mobile {
        &:nth-child(2) {
          display: none;
        }
      }

      @include hover {
        color: $color-white;
        text-decoration: none;

        .Header__contacts-icon {
          fill: $color-white;
        }
      }
    }

    &-icon {
      width: 22px;
      height: auto;
      margin-right: 7px;
      transition: fill 0.3s;
      fill: #eee;
    }
  }

  &__social {
    &-span {
      color: #eee;

      @include tablet {
        display: none;
      }
    }

    &-link {
      margin-right: 10px;
      color: #eee;

      &:hover {
        color: $color-white;
        text-decoration: none;

        .Header__social-icon {
          fill: $color-white;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-icon {
      width: 22px;
      height: auto;
      transition: fill 0.3s;
      fill: #eee;
    }
  }

  &__navbar {
    width: 100%;
    min-height: 65px;
    // background-color: rgba(0,0,0,0.2);
    // background-color: #aeb2b3;

    @include mobile {
      min-height: 60px;
    }
  }

  &__brand {
    @include mobile {
      order: 2;
    }
  }

  &__logo {
    width: 150px;

    @include mobile {
      width: 125px;
    }
  }

  &__logo-text {
    color: white;
    font-size: 10px;
    font-style: italic;

    @include mobile {
      display: none;
    }
  }

  &__burger {
    @include mobile {
      order: 1;
      margin-right: 15px;
    }
  }

  &__links {
    justify-content: flex-end;

    @include mobile {
      order: 4;
    }

    a[class] {
      display: inline-flex;
      align-items: center;
      align-items: center;
      color: #eee;
      font-size: 16px;

      @include hover {
        color: $color-white;

        .Header__links-icon {
          fill: $color-white;
        }
      }
    }

    &-icon {
      display: inline-flex;
      width: 22px;
      height: auto;
      margin-right: 10px;
      transition: fill 0.3s;
      fill: #eee;
    }
  }

  &__langs {
    display: inline-flex;
    align-items: center;

    @include mobile {
      order: 3;
      margin-left: auto;
      padding-left: 0;
      border-left: none;
    }
  }
}
