@import '@styles/variables'; 
@import '@styles/mixins';

.ParkSelectionModal {
  :global {
    .ant-modal-body { text-align: center; }

    .ant-typography + h4.ant-typography { margin-top: 0; }

    .ant-modal-footer {
      padding: 24px;
      padding-bottom: 35px;
      margin-top: -24px;
      border-top: 0;
      text-align: center;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: auto;
    padding-top: 10px;
    @include mobile {
      padding-top: 20px;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    color: $color-accent-title;
    @include mobile {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.5px;
      margin-bottom: 35px;
    }
  }

  &__address {
    @include mobile {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__contacts {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
    @include mobile {
      margin-bottom: 10px;
    }
  }

  &__link {
    display: inline-flex;
    align-items: flex-end;
    transition: all $default-duration;
    color: $color-accent;
    &:hover, &:active { 
      text-decoration: none;
      color: $color-accent-hover;
    }

    @include mobile {
      justify-content: center;
      align-items: center;
      min-height: 35px;
      width: 250px;
      border-radius: 20px;
    }

    &:first-child {
      margin-right: 20px;
      @include mobile {
        margin-right: 0;
        margin-bottom: 20px;
      } 
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 22px;
    line-height: 30px;
    @include mobile {
      flex-direction: column;
      align-items: center;
    }

    &-icon {
      width: 20px;
      height: auto;
      fill: $color-accent;
      margin-right: 5px;
      transition: fill .3s;
    }

    &-title {
      font-size: 18px;
      line-height: 21px;
      color: $color-accent;
      &:hover, &:active { color: $color-accent-hover; }
    }
  }

  &__ok-button[class] {
    color: $color-white;
    background-color: $color-accent;
    border-color: $color-accent;
    &:hover, &:active, &:focus {
      color: $color-white;
      background-color: $color-accent-title;
      border-color: $color-accent;
    }
  }

  &__is-plug {
    :global {
      .ant-modal-footer { display: none; }
    }
  }
}