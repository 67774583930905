@import "@styles/variables";
@import "@styles/mixins";

.Footer {
  background-color: $color-gray-lite;
  opacity: .9;
  margin: 0;
  border-radius: 15px 15px 0 0;
  padding-left: 15px;
  padding-right: 15px;
  @include tablet {
    width: 100%;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @include mobile {
    border-top: 1px solid $color-gray-mid;
  }

  &__nav {
    @include list-reset;
  
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include laptop { justify-content: center;}
    @include mobile { 
      padding-top: 20px;
    }

    &-item {
      display: flex;
      font-size: 16px;

      // &--small {
      //   font-size: 14px;
      //   font-weight: normal;
      // }
    }

    &-link[class] {
      font-weight: 500;
      color: $color-black;
      padding: .5rem 1rem;
      text-decoration: none;
      @include mobile {
        padding: 0 10px;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 10px;
      }
    
      &:hover {
        color: $color-accent;
        text-decoration: none;
      }
    }
  }
}