@import '@styles/mixins';
@import "@styles/animations";

.ParkSelection {
  @extend .fade-in;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  min-height: 186px;
  margin-top: 30px;
  margin-bottom: 30px;
  &--ca { justify-content: flex-start; }

  @include tablet {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 35px;
  }

  @include mobile { margin-bottom: 0; }
  
  // loader
  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 60px;
    margin-left: -35px;
    margin-top: -30px;
  }

  // park item
  &__item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 165px;
    max-width: 183px;
    margin: 10px;
    padding: 8px;
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 8px solid #e2e2e2;
    cursor: pointer;
    &--active {
      border-color: $color-accent;
      background: #f9f9f9;
      & > p {
        color: $color-accent;
        text-shadow: 0 0 0.1px $color-accent;
      }
    }
    @include transition(background-color);
    @include hover { background-color: #f9f9f9; }
    @include tablet {
      flex-basis: 45%;
      max-width: 250px;
      min-height: 167px;
    }
    @include mobile {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 45%;
      max-width: 250px;
      min-height: 167px;
      margin: 5px;
    }
  }

  // park logo
  &__logo {
    max-height: 72px;
    max-width: 100%;
    margin: 8px;
    user-select: none;
    object-fit: contain;
    @include mobile { height: 72px; }
  }

  // park name
  &__text {
    font-size: 15px;
    margin: 8px 0;
    font-weight: 500;
    line-height: 1.3;
    @include mobile { font-size: 14px; }
  }
}