$rt-namespace: 'Toastify';
$rt-toast-width: 320px;
$rt-toast-background: #fff;
$rt-toast-min-height: 64px;
$rt-toast-max-height: 800px;
$rt-toast-border-radius: 4px;
$rt-toast-padding: 8px;
$rt-toast-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1), 0 2px 15px 0 rgba(0, 0, 0, .05);

$rt-color-default: rgba(255, 255, 255, 0.6);
$rt-color-dark: #121212;
$rt-color-info: rgba(0, 142, 169, 0.6);
$rt-color-success: rgba(39, 143, 52, 0.6);
$rt-color-warning: rgba(219, 81, 8, 0.6);
$rt-color-error: rgba(219, 8, 8, 0.6);

$rt-text-color-default: #333333;
$rt-text-color-dark: #f6f6f6 ;

$rt-color-progress-default: rgba(24, 144, 255, 0.5);
$rt-color-progress-info: #00b7d9;
$rt-color-progress-warning: #EA6625;
$rt-color-progress-success: #2cb83e;
$rt-color-progress-error: #e31414;

$rt-color-progress-dark: #bb86fc;
$rt-mobile: "only screen and (max-width : 480px)";
$rt-font-family: sans-serif;
$rt-font-size: 14px;
$rt-z-index: 9999;
