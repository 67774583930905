@import "@styles/variables";
@import "@styles/mixins";

.CompanyAccountHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0.5rem 1rem;
  color: $color-white;
  background-color: #525252;

  @include mobile {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    padding-left: 55px;
  }

  &__burger {
    display: none;
    position: absolute;
    left: 15px;
    align-items: center;
    justify-content: center;
    width: 24px;
    cursor: pointer;
    &-svg { transition: all 0.23s; }
    @include mobile { display: flex; }
  }

  &__logo {
    &-img {
      width: 150px;
      height: auto;
      @include mobile { width: 125px; }
    }
  }
}