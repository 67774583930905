@import "@styles/variables";
@import "@styles/mixins";
@import "@styles/buttons";

.CookieBanner {
  max-width: 350px;
  width: 100%;
  padding: 10px;
  background-color: rgba(255,255,255, 0.95);
  border-radius: 5px;
  text-align: left;
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 100;

  @include mobile {
    max-width: none;
    width: auto;
    bottom: 80px;
    right: 10px;
  }

  &__close {
    position: absolute;
    z-index: 101;
    right: 0;
    top: -30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-image: url("../../../static/icons/close.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px auto;

  }

  &__text {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 5px;

    & > a {
      color: $color-accent;

      @include hover{
        color: $color-accent-hover;
      }
    }
  }

  &__button[class] {
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-sm;
    width: 100%;
    background-color: $color-accent;
    border-color: $color-accent;

    &:hover, 
    &:active,
    &:focus {
      background-color: $color-accent-hover;
      border-color: $color-accent-hover;
    }
  }
}
