@import '@styles/variables';
@import '@styles/mixins';

.App {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 100vw;
    min-height: 100vh;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    transition: .3s ease-out opacity;
    opacity: 0;
    transition: .3s ease-out opacity;
    content: "";
  }

  &--start-animation {
    &::after { opacity: 1; }
  }
}
