@import '~bootstrap/scss/_buttons';

.button {
  height: 44px;
  padding: 0 24px;
  color: $color-white;
  font-size: 18px;
  line-height: 18px;
  background: $color-accent 0 0 no-repeat padding-box;
  border-style: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:disabled,
.button[disabled] {
  background: #969696 0 0 no-repeat padding-box;
  cursor: auto;
}

.btn-outline-secondary {
  @extend .btn-outline-secondary !optional;
}

.btn {
  @extend .btn !optional;

  &-success {
    @extend .btn-success !optional;
  }

  &-warning {
    @extend .btn-warning !optional;
  }

  &-primary {
    @extend .btn-primary !optional;
  }

  &-secondary {
    @extend .btn-secondary !optional;
  }

  &-lg {
    @extend .btn-lg !optional;
  }

  &-sm {
    @extend .btn-sm !optional;
  }
}

.del-btn-content {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 6px auto;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  background: none;
  border: 1px solid gray;
  border-radius: 50%;
  outline: none;
  transition: 0.2s;

  &:disabled {
    opacity: 0.5;
  }
}
