.ErrorWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;

  &__wrapper {
    margin-top: 160px;
    text-align: right;
  }

  &__image {
    position: absolute;
    width: 160px;
    transform: translateX(-160px);
  }
  
  &__text {
    text-align: left; 
  }
  
  &__title {
    margin-bottom: 32px;
    font-size: 36px;
  }

}
