.language-switcher {
  width: 65px;

  .ant-select-selection {
    background-color: transparent;
    border: 1px solid transparent;

    &:focus, 
    &:hover,
    &:active {
      border: 1px solid transparent;
    }
  }

  .ant-select-selection__rendered {
    margin-right: 30px !important;
    
    .ant-select-selection-selected-value {
      width: 100%;
      height: auto;
    }
  }

  .ant-select-arrow {
    svg { fill: #999; }
  }
}
