@import "@styles/variables";
@import "@styles/mixins";

$main-size: 206px;

.CodeSubmitForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include tablet { align-items: stretch; }

  // wrapper
  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    @include tablet { justify-content: space-between; }
    @include mobile {
      margin-top: 10px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &-text {
    margin: 0;
    color: #4e4e4e;
    font-size: 18px;
    line-height: 22px;
    @include mobile {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__subtitle {
    margin-top: 32px;
    font-size: 18px;
    line-height: 22px;
    color: #4e4e4e;
    @include mobile {
      margin-top: 25px;
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__input {
    &-label { 
      margin: 0; 
      @include tablet { flex-grow: 1; }
      @include mobile { width: 100%; }
    }

    // email field
    &-text {
      width: 309px;
      height: 36px;
      padding: 0 8px;
      font-size: 16px;
      background: #fafafa 0 0 no-repeat padding-box; // ???
      border: 1px solid #ced4da;
      border-radius: 4px;
      @include tablet { 
        width: 100%; 
        min-width: $main-size;
      }
      &:focus {
        border: 1px solid $color-accent;
        outline: 1px solid $color-accent;
      }
    }

    // code field
    &-code {
      width: 309px;
      height: 36px;
      padding: 0 8px;
      font-size: 16px;
      background: #fafafa 0 0 no-repeat padding-box;
      border: 1px solid #ced4da;
      border-radius: 4px;
      @include tablet { 
        width: 100%; 
        min-width: $main-size;
      }
      &:focus {
        border: 1px solid $color-accent;
        outline: 1px solid $color-accent;
      }

      // browser specific
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button { margin: 0; -webkit-appearance: none; }
      &[type="number"] { -moz-appearance: textfield; }
    }

    &-text,
    &-code {
      &:disabled,
      &[disabled] {
        cursor: auto;
        background: #e3e3e3 0 0 no-repeat padding-box; // ???
      }
    }
  }

  // buttons
  &__button-wrapper {
    margin-left: 15px;
    @include mobile { 
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }

    & > button {
      min-width: $main-size;
      @include mobile { min-width: 100%; }
    }
  }

  &__descriptor {
    color: #cd0000;
    margin-top: 8px;
    font-size: 14px;
    line-height: 14px;
  }

  // timer
  &__timer {
    margin-top: 8px;
    font-size: 14px;
    line-height: 14px;
  }

  // error
  &__error {
    &-text {
      margin-top: 8px;
      color: #cd0000;
      font-size: 14px;
      line-height: 14px;
    }

    &-input { border: 1px solid #cd0000; }
  }

  &__code {
    &-info {
      margin-top: 8px;
      font-size: 14px;
      line-height: 14px;

      &-error {
        margin-top: 8px;
        color: #cd0000;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
