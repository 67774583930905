@import '@styles/variables';
@import '@styles/mixins';

.StepsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  @include mobile {
    margin-top: 5px;
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;

    // checked
    &.checked {
      & > b {
        color: $color-accent;
        border: 1px solid $color-gray;
        background-color: rgba(255,255,255, 0.8);
        font-size: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px auto;
        background-image: url("../../../static/icons/check-gray.svg");
        opacity: 0.8;
      }
      & > span {
        color: $color-gray; 
        opacity: 0.8;
      }
    }

    // active
    &.active {
      & > b {
        color: $color-accent;
        border: 1px solid $color-accent;
        background-color: $color-white;
        font-weight: 500;
      }
      & > span { 
        color: $color-accent;
        font-weight: 500;
      }
    }

    b {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      font-size: 14px;
      line-height: 14px;
      border: 1px solid lighten($color-gray, 40%);
      color: lighten($color-gray, 40%);
      font-weight: 500;
      flex-shrink: 0;
      @include mobile {
        width: 20px;
        height: 20px;
      }
    }

    span {
      padding-left: 10px;
      padding-right: 10px;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: lighten($color-gray, 40%);
      @include mobile {
        font-size: 13px;
        line-height: 20px;
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }

  &__line {
    position: relative;
    height: 1px;
    background-color: lighten($color-gray, 40%);
    flex-grow: 1;
    flex-basis: 30px;
    margin-right: 8px;
    border-radius: 5px;
  }
}