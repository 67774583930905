@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';

$default-duration: 230ms;

// retina
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

// media
$mobile-max: 767.9px;
$tablet-max: 1023.9px;
$laptop-max: 1279.9px;

// accent color
$color-accent:       #278f34;
$color-accent-hover: darken($color-accent, 2.5%);
$color-accent-title: darken($color-accent, 5%);

// secondary color
$color-secondary: #eee;
$color-secondary-hover: darken($color-secondary, 2.5%);

// additional colors
$color-white:     #ffffff;
$color-black:     #000000;
$color-gray:      #4f4f4f;
$color-gray-lite: lighten($color-gray, 60%);
$color-gray-mid:  lighten($color-gray, 50%);

// font-sizes
$font-size-xxxs: 14px;
$font-size-xxs:  16px;
$font-size-xs:   18px;
$font-size-m:    24px;

// fonts
$system-fonts: 
  system-ui, 
  -apple-system, 
  "Segoe UI", 
  Roboto, 
  "Helvetica Neue", 
  Arial, 
  "Noto Sans", 
  "Liberation Sans", 
  sans-serif, 
  "Apple Color Emoji", 
  "Segoe UI Emoji", 
  "Segoe UI Symbol", 
  "Noto Color Emoji";
