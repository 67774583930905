.#{$rt-namespace}__toast {
  position: relative;
  min-height: $rt-toast-min-height;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: $rt-toast-padding;
  border-radius: $rt-toast-border-radius;
  box-shadow: $rt-toast-box-shadow;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  max-height: $rt-toast-max-height;
  overflow: hidden;
  font-family: $rt-font-family;
  font-size: $rt-font-size;
  text-align: left;
  cursor: pointer;
  direction: ltr;

  @include mobile {
    margin-bottom: 0;
  }

  &--rtl {
      direction: rtl;
  }
  &--dark {
      background: $rt-color-dark;
      color: $rt-text-color-dark;
  }
  &--default {
      background: $rt-color-default;
      color: $rt-text-color-default;

  }
  &--info {
      background: $rt-color-info;
      color: $rt-text-color-dark;
  }
  &--success {
      background: $rt-color-success;
      color: $rt-text-color-dark;
  }
  &--warning {
      background: $rt-color-warning;
      color: $rt-text-color-dark;
  }
  &--error {
      background: $rt-color-error;
      color: $rt-text-color-dark;
  }
  &-body {
      margin: auto 0;
      flex: 1 1 auto;
  }
}
