@import "@styles/variables";
@import "@styles/mixins";

.Login {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;

  @include mobile {
    max-width: 220px;
  }

  &__item {
    padding: 0 5px;
    font-size: 16px;
    line-height: 18px;

    &:last-child {
      margin-left: 10px;
      font-weight: 600;
      cursor: pointer;

      @include tablet {
        margin-left: 5px;
      }
    }

    @include tablet {
      padding: 0 3px;
      font-size: 14px;
      line-height: 18px;
    }

    @include mobile {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--separator {
      @include tablet {
        margin-left: 5px;
      }
    }

    &--company-name {
      max-width: 300px;
      max-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  
      @include tablet {
        display: none;
      }
    }
  }
}
