@import '@styles/variables';
@import '@styles/mixins';

.RulesList {
  padding-left: 23px;
  margin: 0;
  margin-top: 5px;
  list-style: none;

  & > li {
    font-size: 14px;
    line-height: 18px;
    a {
      position: relative;
      color: $color-accent;
      transition: all $default-duration;
      padding-left: 15px;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-accent;
      }
      &:hover { color: $color-accent-hover; }
    }
  }
}